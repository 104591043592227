<template>
    <div class="vueperslides__track-inner">
        <div class="vueperslide">
            <div class="vueperslide__content-wrapper">
                <div class="card-prize">
                    <div class="vueperslide__title">
                        <h5>{{prizesContent.year}}</h5>
                    </div>
                    <div class="vueperslide__content">
                        <h3 v-html="prizesContent.title"></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        prizesContent: {
            type: Object
        }
    }
}
</script>

<style scoped>
.card-prize{
    width: auto;
    height: 80%;
}
::deep(.vueperslide__title){
    text-align: left;
    color: var(--primary-color);
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 8px;
}
::deep(.vueperslide__content){
    color: var(--secondary-color);
    text-align: left;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 12px;
    max-width: 270px;
}
.vueperslide__content h3{
    padding: 0 10px;
    color: white !important;
}
.vueperslide__title h5{
    color: white !important;
}
.vueperslide__content span {
    display: block;
    font-size: 14px;
    line-height: 24px;
}
</style>